s
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
//import DatePicker from "vue2-datepicker";
import appConfig from "@/app.config";
//import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";
import router from "@/router/index.js";
//import { updateLocale } from '../../../../node_modules/moment/ts3.1-typings/moment';

/**
 * Orders Component
 */
export default {
  page: {
    title: "Statistique",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    /* ckeditor: CKEditor.component, */
  },
  data() {
    return {
      title: "Statistique",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "Statistique",
          active: true,
        },
      ],
      value: null,
      value1: null,
      tyMessage:[
        {
          name:"Notification",
          value:0
        },
        {
          name:"Mail",
          value:1
        },
        {
          name:"Notification & Mail",
          value:2
        }
      ],
      tyMedia:[
        {
          name:"",
          value:""
        },
        {
          name:"Image",
          value:"image"
        },
        {
          name:"Vidéo",
          value:"video"
        }
      ],
      messageSelect:"",
      mediaSelect:"",
      messageContent:[
        {
          sujet:"",
          message:""
        }
      ],
      sujet:"Demande de vérification",
      url_link:"",
      idUser: 0,
      dataUser: [],
      usersdataId: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      verify: {
        email: false,
        permis: false,
        autoMoto: false,
        image: false,
        tel: false,
        cni: false,
      },
      ordersData: [
        {
          userid: "photo & nom",
          usertype: "CONDUCTEUR",
          piece: "PERMIS",
        },
        {
          userid: "photo & nom",
          usertype: "PARTICULIER",
          piece: "NUMERO DE TELEPHONE",
        },
        {
          userid: "photo & nom",
          usertype: "CONDUCTEUR",
          piece: "CNI",
        },
        {
          userid: "photo & nom",
          usertype: "PARTICULIER",
          piece: "PROFILE",
        },
      ],
      fields: [
        { key: "username", label: "Utilisateur", sortable: true },
        { key: "userType", sortable: true, label: "Type d'Utilisateur" },
        { key: "piece", sortable: true, label: "Pièces non vérifiées" },
      ],
      sujet_message:"",
      message:null,
      rowValue:"",
      messagecni:null,
      messageauto:null,
      messagepermis:null,
      updateValue:"",
      showDelete: false,
      showEchec:false,
      showSucess:false,
      showEdit: false,
      showModal: false,
      showPassager:false,
      profiList: false,
      cniList: false,
      vehiculeList: false,
      telList: false,
      all:false,
      test:null,
      conducteur:[],
      checkedPieces: [],
      editor: ClassicEditor,
      editorData:
        "Salut veuillez faire vérifier les informations suivantes /n",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    }
  },
  async mounted() {
    //Chargement des données
    const data = await apiRequest(
      "GET",
      "admin/users-no-verif",
      undefined,
      false
    );
    if (data && data.data) {
      console.log("data.data:", data.data);
      const formattedTable = data.data.map((user) => {
        var pieces = {
          automoto:user.autoMotoVerified == false ? "- AUTO MOTO" : "",
          cni:user.cniVerified == false ? "- CNI" : "",
          picture:user.pictureVerified == false ? "- PHOTO PROFIL" : "",
          permis:user.permisVerified == false ? "- PERMIS" : "",
        }
        return {
          id: user.id,
          username: user.lastname + " " + user.firstname,
          userType: user.userType ? user.userType : "UNDEFINED",
          //conducteur: user.userType,
          piece: pieces.automoto + " -- " + pieces.cni + " -- " + pieces.picture + " -- " + pieces.permis
        };
      });
      this.dataUser = formattedTable;
    }
    // Set the initial number of items
    this.totalRows = this.dataUser.length;
  },
  methods: {
    EditModal() {
      this.submitted = false;
      this.showEdit = false;
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async SendToDriver(){
      this.test = "conducteur";
      this.Refresh();
      this.usersdataId = [];
      for(let i=0; i<this.dataUser.length;i++){
        if(this.dataUser[i].userType == "CONDUCTEUR"){
          this.usersdataId.push(this.dataUser[i].id);
        }
      }
      console.log("conducteur id:",this.usersdataId);
      this.showModal = true;
       const message = await apiRequest(
        "POST",
        "admin/message-users",
        {
          idUsers: this.usersdataId,
          typeMessage: this.messageSelect.value,
          subject: this.sujet_message,
          text: this.editorData,
          media_type: this.tyMedia.value ? this.tyMedia.value : "",
          url: this.url_link
        },
        false
      );
      if (message && message.data) {
        this.showMessage = false;
        console.log(message);
        this.showSucess = true;
      } else {
        this.showEchec = true;
      }

    },

    async SendToPassenger(){
      this.test = "passager";
      this.Refresh();
       this.usersdataId = [];
      for(let i=0; i<this.dataUser.length;i++){
        if(this.dataUser[i].userType != "CONDUCTEUR"){
          this.usersdataId.push(this.dataUser[i].id);
        }
      }
      console.log("passager id:",this.usersdataId);
      this.showModal = true;

       const message = await apiRequest(
        "POST",
        "admin/message-users",
        {
          idUsers: this.usersdataId,
          typeMessage: this.messageSelect.value,
          subject: this.sujet_message,
          text: this.editorData,
          media_type: this.tyMedia.value ? this.tyMedia.value : "",
          url: this.url_link
        },
        false
      );
      if (message && message.data) {
        this.showMessage = false;
        console.log(message);
        this.showSucess = true;
      } else {
        this.showEchec = true;
      }

      
      
    },

    AllChecked(){
      if(this.all == false){
        this.messagecni = true
      
      this.message = true
      this.editorData = [];
      this.editorData = "Salut veuillez faire vérifier les informations suivantes \n"
      this.checkedPieces = [];
      this.checkedPieces.push("- PROFIL")
      this.checkedPieces.push("- CNI")
      
      if(this.test == "conducteur"){
        this.checkedPieces.push("- AUTO/MOTO")
      this.checkedPieces.push("- PERMIS")
      this.messageauto = true
      this.messagepermis = true
      }
      this.update();
      }else{
         this.messagecni = false
      this.messageauto = false
      this.messagepermis = false
      this.message = false
      this.checkedPieces = [];
      this.editorData = [];
      this.update();
      }
    },

    goToDetail(row) {
      console.log("row:", row);
      router.push(`/user/verification/${row.id}`);
      //router.push('/user/user_info/'+row.id)
    },
    update(){
       this.updateValue="";
     if(this.checkedPieces.length>0){
       this.editorData="Salut veuillez faire vérifier les informations suivantes \n";
       for(let i=0; i<this.checkedPieces.length;i++){
           this.updateValue+=this.checkedPieces[i]+"\n"
          }
          this.editorData+=this.updateValue
     }else{
        this.editorData="Salut veuillez faire vérifier les informations suivantes \n";
     }
    },
    changeProfil(){

      if(this.message!=true){
        
        if(this.checkedPieces.length>0){
       for(let i=0; i<this.checkedPieces.length;i++){
         if(this.checkedPieces[i]=="- PROFIL"){
               console.log("")
         }else{
           this.checkedPieces.push("- PROFIL")
            this.update();
            break;
         }
       }
      }else{
        this.checkedPieces.push("- PROFIL")
         this.update();

      }
      }else{
        this.all = false;
        if(this.checkedPieces.length>0){
        for(let i=0; i<this.checkedPieces.length;i++){
           if(this.checkedPieces[i]=="- PROFIL"){
                this.checkedPieces.splice(i,1);
               this.update();
         }
          }
           }
      }
    },
    changeCni(){
      if(this.messagecni!=true){
        if(this.checkedPieces.length>0){
       for(let i=0; i<this.checkedPieces.length;i++){
         if(this.checkedPieces[i]=="- CNI"){
               console.log("")
         }else{
           this.checkedPieces.push("- CNI")
            this.update();
             break;
         }
       }
      }else{
        this.checkedPieces.push("- CNI")
         this.update();
      }
      }else{
        this.all = false;
        if(this.checkedPieces.length>0){
        for(let i=0; i<this.checkedPieces.length;i++){
           if(this.checkedPieces[i]=="- CNI"){
                this.checkedPieces.splice(i,1);
               this.update();
         }
          }
           }
      }
    },
    changeAuto(){
      if(this.test=="conducteur"){
      if(this.messageauto!=true){
        if(this.checkedPieces.length>0){
       for(let i=0; i<this.checkedPieces.length;i++){
         if(this.checkedPieces[i]=="- AUTO/MOTO"){
               console.log("")
         }else{
           this.checkedPieces.push("- AUTO/MOTO")
            this.update();
             break;
         }
       }
      }else{
        this.checkedPieces.push("- AUTO/MOTO")
         this.update();
      }
      }else{
        this.all = false;
        if(this.checkedPieces.length>0){
        for(let i=0; i<this.checkedPieces.length;i++){
           if(this.checkedPieces[i]=="- AUTO/MOTO"){
                this.checkedPieces.splice(i,1);
               this.update();
         }
          }
           }
      }
    }
    },
    changePermis(){
      if(this.all == "conducteur"){
      if(this.messagepermis!=true){
        if(this.checkedPieces.length>0){
       for(let i=0; i<this.checkedPieces.length;i++){
         if(this.checkedPieces[i]=="- PERMIS"){
               console.log("")
         }else{
           this.checkedPieces.push("- PERMIS")
            this.update();
             break;
         }
       }
      }else{
        this.checkedPieces.push("- PERMIS")
         this.update();
      }
      }else{
        this.all = false;
        if(this.checkedPieces.length>0){
        for(let i=0; i<this.checkedPieces.length;i++){
           if(this.checkedPieces[i]=="- PERMIS"){
                this.checkedPieces.splice(i,1);
               this.update();
         }
          }
           }
      }
    }
    },
    Refresh(){
      this.showModal = true;
      this.checkedPieces = [];
      this.message=null
      this.messageauto=null
      this.messagepermis=null
      this.messagecni=null
      this.editorData = "Salut veuillez faire vérifier les informations suivante \n"
    },
    fonctionRow(row){
     this.Refresh();
      this.rowValue=row;
    },
    async addMessage() {
      this.showMessage = false;
      const data = await apiRequest(
        "POST",
        "admin/message-user",
        {
          idUser: this.usersdataId,
          typeMessage: this.messageSelect.value,
          subject: this.sujet,
          text: this.editorData,
          media_type: this.tyMedia.value ? this.tyMedia.value : "",
          url: this.url_link
        },
        false
      );
      //console.log("data.data:", data);
      if (data && data.data) {
        console.log(data);
        this.showSucess = true;
      } else {
        this.showEchec = true;
      }
      //console.log("id:", this.usersdataId)
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6 mb-2">
                    <button class="btn btn-primary" @click="SendToDriver">Envoyer aux conducteurs</button>
                    <button class="btn btn-success ml-3" @click="SendToPassenger">Envoyer aux passagers</button>
                    <!-- <label class="d-inline-flex align-items-center">
                      <multiselect
                        v-model="value1"
                        :options="options"
                        :multiple="true"
                        :searchable="false"
                        class="col-md-12"
                      ></multiselect>
                    </label> -->
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="dataUser"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template v-slot:cell(badge)="row">
                      <div
                        class="text-center"
                        :class="{
                          'fa fa-star': `${row.value}` === 'false',
                          'fa fa-star badge-soft-warning':
                            `${row.value}` === 'true',
                        }"
                      ></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Suspendu',
                          'badge-soft-success': `${row.value}` === 'Actif',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <!-- <template v-slot:cell(action)="row">
                      <button class="btn btn-primary" @click="fonctionRow(row.item)">
                        Envoyer
                      </button>
                    </template> -->
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- -->
    <b-modal v-model="showModal" title="Nouveau Message"  centered>
      <div class="row mb-3">
        <div class="col-md-3">
          <input type="checkbox" value="PROFIL" v-model="message" @click="changeProfil()">
          <span class="ml-1">PROFIL</span>
        </div>
         <div class="col-md-2">
          <input type="checkbox" value="CNI" v-model="messagecni" @click="changeCni()">
          <span class="ml-1">CNI</span>
        </div>
         <div class="col-md-4">
          <input type="checkbox" value="AUTO/MOTO" v-model="messageauto" @click="changeAuto()">
          <span class="ml-1">AUTO/MOTO</span>
        </div>
         <div class="col-md-3">
          <input type="checkbox" value="PERMIS" v-model="messagepermis" @click="changePermis()">
          <span class="ml-1">PERMIS</span>
        </div>
        <h6></h6>
        <div class="col-md-3">
          <input type="checkbox" value="Cocher" v-model="all" @click="AllChecked()">
          <span class="ml-1">TOUT</span>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label">Choisir le type de message:</label>
        <multiselect
          v-model="messageSelect"
          :options="tyMessage"
          label="name"
          track-by="value"
        ></multiselect>
      </div>
      <form>
        <div class="form-group">
          <input type="text" v-model="sujet_message" class="form-control" placeholder="Sujet">
        </div>
        <div class="form-group">
          <!-- <ckeditor v-model="editorData" :editor="editor"></ckeditor> -->
          <textarea class="form-control" v-model="editorData" id="" cols="30" rows="10"></textarea>
        </div>
        <div class="form-group">
          <label class="control-label">Choisir le type de média:</label>
        <multiselect
          v-model="mediaSelect"
          :options="tyMedia"
          label="name"
          track-by="value"
        ></multiselect>
        </div>
        <div class="form-group">
          <input type="text" class="form-control" v-model="url_link" placeholder="Lien du média">
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModal = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="addMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>


      <b-modal
      id="modal-backdrop"
      centered
      v-model="showSucess"
      title="Message envoyé avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSucess = false">OK</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchec"
      title="L'envoie a échoué"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchec = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>
  </Layout>
</template>
